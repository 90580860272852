<template>
  <div>
    <b-form @submit="onSubmit">

      <b-form-file
        v-model="file"
        :state="Boolean(file)"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
      <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
      <b-button type="submit" variant="primary">Envoyer</b-button>
    </b-form>

  </div>
</template>

<script>

export default {
  name: 'RaceUpload',
  data() {
    return {
      file: null,
    };
  },
  methods: {
    async onSubmit() {
      const formData = new FormData();
      formData.append('file', this.file);
      await this.axios.post('/race/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      this.$router.push({ path: '/race/pending/all' });
    },
  },
};
</script>
